.search-form {
  padding-right: 2rem;
  text-align: right;
}

.search-input {
  background-color: whitesmoke;
  color: #38015c;
  border-radius: 4px;
  border: 2px solid #f6f6f6;
  width: 17rem;
  padding: 0.5rem 1rem;
}

.search-input:focus {
  outline: none;
  border-bottom: 2px solid #46c3cf;
}
