.layout {
    display: flex;
}

.main {
    height: 100vh;
    width: 100%;
    overflow-y: scroll;
}

.main-content {
    display: flex;
    flex-wrap: wrap;
}