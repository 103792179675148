.activate-button {
  background-color: rgb(103, 209, 103);
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
}

.activate-button:hover {
    background-color: rgb(161, 224, 161);
}

.deactivate-button {
  background-color: rgb(230, 120, 93);
  cursor: pointer;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
}

.deactivate-button:hover {
    background-color: rgb(235, 164, 146);
}