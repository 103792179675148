.main {
  flex: 1;
}

.search-row {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0rem;
}

.user-actions {
  padding: 0rem 2rem;
}

.user-actions button {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #46c3cf;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
  margin-right: 1rem;
}

.user-actions button:hover {
  background-color: #50e1ee;
  border-color: #873abb;
}
