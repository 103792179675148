* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  color:black;
  background-color: white;
  overflow-y: hidden;
}

@font-face {
  font-family: 'Rubik';
  src: local('Rubik'), url(./fonts/Rubik/static/Rubik-Regular.ttf) format('truetype');
}

:root {
  --speed: 500ms;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;
}