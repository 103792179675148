.table-container {
    display: flex;
    height: 100%;
    padding: 0rem 2rem 1rem 2rem;
}

.edit-button {
    background-color: whitesmoke;
}

.edit-button:hover {
    background-color: white;
}
