.header {
  display: flex;
  flex-flow: row nowrap;
  height: 4rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
}

.header h2 {
  margin-left: 2rem;
}

.navbar-nav {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-item:hover{
  cursor: pointer;
}

.icon-button {
  text-decoration: none;
}

.icon-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-menu h4 {
  padding: 0.5rem;
  text-decoration: none;
  color: rgb(20, 25, 48);
}

.icon-button svg {
  fill: var(--text-color);
  width: 10px;
  transform: rotate(90deg);
}

.icon-button-active svg {
  transform: rotate(0deg);
}

.dropdown {
  position: absolute;
  top: 3rem;
  width: 11rem;
  transform: translateX(-15%);
  overflow: hidden;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 2px rgba(0, 0, 0, 0.1), -1px 1px 2px 2px rgba(0, 0, 0, 0.1);
  /* padding: 1rem; */
}

.menu-item {
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content:center;
  transition: background var(--speed);
  border-radius: 5px;
  margin: 0.5rem;
  padding: 1.25rem;
}

.menu-item:hover {
  background-color:rgba(187, 226, 223, 0.589);
}

.icon-right {
  margin-left: auto;
}

.icon-left {
  margin-right: auto;
}