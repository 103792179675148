.ibx-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 1rem 2rem 1rem;
}

.ibx-logo img {
  width: auto;
}

.control {
  display: flex;
  align-items: center;
  margin: 1rem;
  width: 20rem;
}

.control input {
  font: inherit;
  align-items: center;
  text-align: center;
  background-color: whitesmoke;
  color: #38015c;
  border-radius: 4px;
  border: 2px solid #f6f6f6;
  width: 100%;
  padding: 1rem;
}

.control input:focus {
  outline: none;
  border-bottom: 2px solid #46c3cf;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.actions button {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #46c3cf;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.actions button:hover {
  background-color: #50e1ee;
  border-color: #873abb;
}
