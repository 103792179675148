.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    box-shadow: 1px 1px 8px 4px rgba(0, 0, 0, 0.1), -1px 2px 8px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: white;
    height:fit-content;
    width: auto;
}