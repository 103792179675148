.control {
  display: flex;
  margin: 1rem;
  flex: 1;
  justify-content: center;
  padding: 0% 20%;
}

.control label {
  font: inherit;
  align-items: center;
  text-align: right;
  color: #38015c;
  border-radius: 4px;
  flex: 2;
  padding: 1rem;
}

.control input {
  font: inherit;
  align-items: center;
  text-align: center;
  background-color: whitesmoke;
  color: #38015c;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 2px solid #f6f6f6;
}

.control select {
  font: inherit;
  align-items: center;
  text-align: center;
  text-align-last: center;
  justify-content: center;
  background-color: whitesmoke;
  color: #38015c;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 2px solid #f6f6f6;
}

.input-container select:focus {
  outline: none;
  border-bottom: 2px solid #46c3cf;
}

.input-container {
  flex: 6;
}

.input-container input:focus {
  outline: none;
  border-bottom: 2px solid #46c3cf;
}

.input-container-error {
  flex: 6;
}

.input-container-error input:focus {
  outline: none;
  border-bottom: 2px solid #f05112;
}

.error-container {
  display: flex;
  margin: 0rem 1rem;
  padding: 0% 20%;
  font-size: 0.8rem;
}

.icon {
  width: 2rem;
}

.error-content{
  display: flex;
  justify-content: left;
  align-items: center;
  flex: 6;
  color: #f05112;
}

.error-container p {
  font-size: 0.8rem;
}

.error-container-corner {
  position: relative;
}

.error-container-corner p {
  position: absolute;
  width: 100%;
  text-align: right;
  padding: 0.1rem;
  font-size: small;
  color: #f05112;
  margin: 0;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 20%;
  margin: 1rem;
}

.submit-space {
  flex: 2;
  padding: 1rem;
}

.buttons {
  display: flex;
  flex: 6;
  align-items: center;
  justify-content: center;
}

.link-button {
  font: inherit;
  color: white;
  background-color: #46c3cf;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
  margin: 0rem 0.5rem;
}

.link-button:hover {
  background-color: #50e1ee;
}

.actions button {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #55e9b7;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
  margin: 0rem 0.5rem;
}

.actions button:hover {
  background-color: #00ffaa;
}
