.sidebar-cont {
  display: flex;
  flex-flow: row;
  height: 100vh;
}

.ibx-logo {
  display: flex;
  align-items: center;
  background-color: #46c3cf;
  box-shadow: 0px 2px 4px rgb(0, 0, 0, 0.2);
  width: 13rem;
  height: 5rem;
}

.ibx-logo img {
  margin-left: 1rem;
  width: auto;
  height: 70%;
}

.navigation {
  /* position: fixed;
  top: 0;
  left: 0; */
  display: flex;
  background-color: #46c3cf;
  height: 100vh;
  width: 13rem;
  box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.2);
  z-index: 30;
}

.sidebar {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 13rem;
  background-color: #46c3cf;
}

.sidebar ul {
  margin-top: 1rem;
  padding: 0;
  width: 100%;
  list-style: none;
}

.sidebar li {
  height: 5rem;
  padding: 0.7rem 0rem 0.7rem 0rem;
  display: flex;
  align-items: center;
  list-style: none;
}

.nav-button {
  height: 4rem;
  width: 100%;
  text-decoration: none;
  color: #f5f5f5;
  font-size: 1rem;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  transition: background var(--speed);
}

.nav-button.active {
  background-color:#47dbe99f
}

.nav-button:hover {
  background-color:#47dbe99f
}

.nav-button .icon {
  font-size: 2.4rem;
  margin-right: 1rem;
}

@media (max-width: 768px) {
  .navigation {
    width: 10%;
  }
}